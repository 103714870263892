import { errors } from "./conts";

const rx = new RegExp(/(?!^\d*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9].{7,})/);
export const validate_pass = (new_pass, cf_pass, setErr) => {    
    if (!(new_pass && cf_pass)) {
        setErr(errors.fill_info);
        return; 
    }
    if (!rx.test(new_pass)) {
        setErr(errors.pass_criteria);
        return;
    }
    if (new_pass !== cf_pass) {
        setErr(errors.pass_mismatch);
        return;
    }
    return true;
}
export const update_password = async (new_password) => {
    let url = " https://4r936c8vh8.execute-api.us-east-1.amazonaws.com/customer/changepass";
    try {        
        const token = get_query_var("token");
        if (token) {
            let headers = new Headers();
            headers.append("Accept", "application/json");
            headers.append("Content-Type", "application/json");
            const conf = {
                method: "POST",
                body: JSON.stringify({ new_password, token }),
                headers,
                mode: "cors",
            };
            const req = new Request(url);
            const res = await fetch(req, conf);
            const {message} = await res.json();
            if (!/2\d\d/.test(res.status)) throw new Error(message);
        }
        else {
            throw new Error("");
        }
    } catch {
        throw new Error("");
    }
}

export const extract_credit = () => {
    const credit = get_query_var("credit");
    return credit;
}

export const check_pass_criteria = (new_pass, setErr) => setErr(rx.test(new_pass) ? "" : errors.pass_criteria);

const get_query_var = (variable) => {
    try {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split("=");
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    } catch {
        throw new Error();
    }
}