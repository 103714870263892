import React, { useEffect, useState } from 'react';

let t = 5
const Success = () => {
    const [seconds, setSeconds] = useState(t);
    useEffect(() => {
        setInterval(() => {
            t <= 1 && window.location.replace("https://www.springbok-puzzles.com");
            setSeconds(s => s - 1);
            t--;
        }, 1000);
    }, []);

    return (<div className="success-message">
        <h3>You password has been updated successfully!!</h3>
        <div className="timer">
            You will be redirected to springbok website in <b>{seconds}</b> secs
        </div>
    </div>)
}

export default Success;
