
export const errors = {
    pass_criteria: "Passwords must be at least 7 characters and contain both alphabetic and numeric characters.",
    pass_mismatch: "New Password and Confirm Password doesn't match",
    fill_info: "Password should not be blank",
    update_err: "Error in updating password. Please try later",
}

export const input_types = {
    password: "password",
    text: "text"
}