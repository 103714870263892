import React, { useState } from 'react';
import Form from './Form';
import Success from './Success';

const App = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  return (<div className="app-container">
    {isSuccess ?
      <Success /> : <Form setIsSuccess={setIsSuccess} />}
  </div>)
}

export default App;
